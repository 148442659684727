<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.PAYMENTS") }}</span>
      </div>
    </div>

    <purchases-payment-list-table :filterInvoice="purchasesInvoice.id" />

    <div class="row mb-5">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-6 text-right">
            {{
              purchasesInvoice.total_paied !== undefined
                ? $formatCurrency(purchasesInvoice.total_paied)
                : null
            }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import PurchasesPaymentListTable from "../../PurchasesPaymentManagement/partials/PurchasesPaymentListTable.vue";

export default {
  name: "purchases-invoice-view-details",

  components: { PurchasesPaymentListTable },

  mixins: [formMixin],

  props: ["purchasesInvoice"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
