import moment from "moment";

export default {
  type: "purchases-invoices",
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  code: null,
  excerpt: "",
  discounts: [],
  relationshipNames: ["issuer", "organization", "allowedLocations", "purchasesOrder"],
  issuer: {
    type: 'suppliers',
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  purchasesOrder: {
    type: "purchases-orders",
    id: null,
  },
  items: [],
  allowedLocations: [],
};
