var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-5"},[_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.purchasesInvoice.items}},[_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CODE'),"prop":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.purchasesInvoiceable)?_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToPurchasesInvoiceable(row.purchasesInvoiceable)}}},[_vm._v(" "+_vm._s(row.code)),_c('br'),_c('div',{staticClass:"text-muted purchases-invoice-item-excerpt",domProps:{"innerHTML":_vm._s(row.excerpt)}})])]):_vm._e(),(!row.purchasesInvoiceable)?_c('div',[_vm._v(" "+_vm._s(row.code)),_c('br'),_c('div',{staticClass:"text-muted purchases-invoice-item-excerpt",domProps:{"innerHTML":_vm._s(row.excerpt)}})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.UNIT_PRICE'),"prop":"unit_price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.unit_price)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.QUANTITY'),"prop":"quantity"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.SUBTOTAL'),"prop":"discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.pricing.subtotal.toFixed(2))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.DISCOUNT'),"prop":"discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.pricing.discount_amount.toFixed(2))+" "),_c('span',{staticClass:"text-muted"},[_vm._v("("+_vm._s(row.discount)+"%)")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.TAXES')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.pricing.taxes.total.toFixed(2))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.TOTAL')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.pricing.total.toFixed(2))+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[(_vm.purchasesInvoice.status === _vm.INVOICE_STATUS_DRAFT)?_c('el-tooltip',{attrs:{"content":_vm.$t('COMMON.EDIT'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.openPurchasesInvoiceItemModal(row)}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e()],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }